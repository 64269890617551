
import { defineComponent } from "vue";

export default defineComponent({
  name: "EventCard",
  props: {
    // event: Object,
    event: Object,
  }
});
